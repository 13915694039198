@import "~animate.css/animate.min.css";
@import "theme";
@import "palette";
@import "flickity";
@import "utilities";
@import "layout";
@import "list";
@import "buttons";
@import "forms";
@import "typography";
@font-face {
    font-family: Ubuntu-Regular;
    src: url("./assets/fonts/ubuntu/Ubuntu-Regular.ttf");
}

@font-face {
    font-family: Ubuntu-Bold;
    src: url("./assets/fonts/ubuntu/Ubuntu-Bold.ttf");
}

:root {
  --handwritten-family-alt: 'Reenie Beanie', cursive;
  --handwritten-family-preferred: 'Indie Flower', cursive;
  --handwritten-family: var(--handwritten-family-preferred);
}

html,
body {
    margin: 0;
    box-sizing: border-box;
    font-family: "Work sans";
    color: $primary-text-color;
    min-height: 100vh;

    * {
        box-sizing: border-box;
    }
}

a {
  @extend .color-primary-900;
    .mat-icon {
        color: white;
    }
}

.toolbar-spacer {
    flex: 1 1 auto;
}

body {
    display: flex;
    background-color: #f4f4f4;
    &.mobile.keyboard {
        .page-actions {
            bottom: 185px;
        }
        @media screen and (min-width: 768px) {
            .page-actions {
                bottom: 365px;
            }
        }
    }

}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: "liga";
}

.cdk-overlay-container {
    z-index: 100000;
}

/* Rules for sizing the icon. */

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}
