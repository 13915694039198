@import 'palette';
.list {
    display: flex;
    flex-direction: column;
    &-title {
        background: white;
        margin: 0;
        padding: 1em;
        border-bottom: $dark-accent 1px solid;
    }
    &-item {
      display: flex;
      background: white;
      height: 2em;
      padding: 1.5em 1.5em;
      line-height: 2;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
        .list--selectable & {
            cursor: pointer;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    &--simple {
        .list-item {
            border-radius: 2em;
        }
    }
    &--square {
        .list-item {
            margin: 0;
            border-radius: 0;
            border-bottom: $dark-accent-transparent 1px solid;
        }
    }
}

.list-item span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1vw;
}
.sub-list {
    margin-left: 1em;
}

.mat-list-item {
    &.selected {
        background: #ddd;
        border-bottom: solid 1px #bbb;
    }
}

.cdk-drag-preview {
    opacity: 0.7;
    display: flex;
    background: white;
    height: 2em;
    padding: 0.5em 1.5em;
    margin: 0.5em 0;
    line-height: 2;
    justify-content: space-between;
    align-items: center;
}

.cdk-drag-placeholder {
    opacity: 0.2;
    border: 1px dashed #BBB;
    display: flex;
    background: white;
    height: 2em;
    padding: 0.5em 1.5em;
    margin: 0.5em 0;
    line-height: 2;
    justify-content: space-between;
    align-items: center;
    box-shadow: #d6d6d6 -2px 4px 10px;
    .session-owner_groups_group & {
        margin-left: 1em;
    }
}

/* Animate items as they're being sorted. */

.cdk-drop-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */

.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
    border: none;
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
