.u-text-center {
    text-align: center;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-text-smaller {
    font-size: 0.8em;
}

.rotate_180 {
    transform: rotate(180deg);
}