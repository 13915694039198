@import "palette";
.form-control-group {
    padding: 1em 2em;
    a {
        color: $primary-text-color;
    }
}

.form-control {
    &--block {
        input,
        button,
        .mat-form-field {
            width: 100%;
        }
    }
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    margin: 0 2rem;
    .mat-form-field--normal & {
        margin: 0;
    }
}

.mat-form-field {
    font-family: "Work sans";
    input {
        padding: 0 2rem;
        .mat-form-field--normal & {
            padding: 0;
        }
    }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgb(203, 210, 217);
}
