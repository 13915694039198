@import 'palette';
a.btn {
    text-decoration: none;
}
.btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    &--block {
        width: 100%;
        margin-bottom: 1em !important;
        margin-right: 1em !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }
    &--subtle {
        background-color: #263238;
        color: white;
        padding: 0.5em 1em !important;
        &_cta {
            background-color: #cfd8dc;
            color: $primary-text-color;
            padding: 0.5em 1em !important;
        }
    }
    &--white {
        background: white;
        border: none;
    }
    &--squared {
        border-radius: 0 !important;
    }
    &--no-margin {
        margin-right: 0 !important;
        margin-bottom: 0 !important;
    }

    &--raised {
        box-shadow: 1px 3px 5px 1px #9aa5b173;
    }

    &--icon-left {
        padding: 0;
        display: flex;
        align-items: center;
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: palette(neutral, 'c100');
            .mat-icon {
                color: palette(neutral, 'c700');
                height: 1em;
                width: 1em;
                font-size: 150%;
                margin: 0.3em;
            }
        }

        .text {
            text-align: center;
            flex-grow: 1;
            color: palette(neutral, 'c800');
        }

        &:hover {
            .icon {
                transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
                background: palette(accent, 'c500');
                .mat-icon {
                    color: white;
                }
            }

            .text {
                color: palette(accent, 'c500');
            }
        }
    }

    &--condensed {
        width: auto;
        display: inline-flex;

        .text {
            margin-left: 0.5em;
        }

        .mat-icon {
            height: 0.8em;
            width: 0.8em;
            font-size: 120%;
            margin: 0.2em;
        }
    }
}

button.mat-stroked-button,
a.mat-stroked-button {
    width: auto;
    border-radius: 2px;
    padding: 0.5em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;

    &.size-18 .mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
    }
}
.mat-mini-fab {
    &.with-content {
        width: auto;
        border-radius: 20px;
        padding: 0 1rem;

        .mat-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
    }
}

