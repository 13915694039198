h1 {
    font-family: Ubuntu-Bold;
    font-size: 30px;
    color: #263238;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    padding: 0.5em 0;
}