@import 'palette';

.page-wrapper {
    display: flex;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);

      flex-direction: column;
      overflow: auto;

    .page-header {
        margin: 0;
        z-index: 2;
    }
    .page-content {
        flex-grow: 1;
        display: flex;
        overflow: auto;
    }
    .page-footer {
        background-color: palette(neutral, 'c050');

        height: auto;
        flex-shrink: 0;
    }
}

router-outlet {
    &+* {
        width: 100%;
    }
}


.page-actions button, .page-actions a  {
  margin: 0 0.5rem;

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }
  &:first-child{
    margin-left: 0;
  }

//   .mat-icon {
//     width: 18px;
//     height: 18px;
//     font-size: 18px;
//     line-height: 18px;
// }
}
